import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Main as MainLayout } from './layouts';
import './App.css';
import {
  Files as FilesView,
  Sip as SipView,
  Iva as IvaView,
  NotFound as NotFoundView,
} from './views';

function App() {
  return (
    <Router>
      <Switch>
        {/* <Route exact path="/">
          <MainLayout>
            <DocumentationView/>
          </MainLayout>
        </Route>
        <Route exact path="/guides">
          <MainLayout>
            <GuidesView/>
          </MainLayout>
        </Route>
        <Route exact path="/support">
          <MainLayout>
            <SupportView/>
          </MainLayout>
        </Route> */}
        <Route exact path="/api/v1/files">
            <FilesView />
        </Route>
        <Route exact path="/api/v1/sip">
            <SipView />
        </Route>
        <Route exact path="/api/v1/iva">
            <IvaView />
        </Route>
        <Route path="*">
          <NotFoundView />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

import React from 'react';
import {Helmet} from "react-helmet";
import { RedocStandalone } from 'redoc';

const Iva = () => {
  return (
    <React.Fragment>
        <RedocStandalone specUrl="/illuma-shield-iva-api-v1.0.yaml" options={{
            theme: {
            typography: {
                fontSize: '1rem'
            },
            colors: {
                primary: {
                main: '#263238'
                }
            }
            },
            jsonSampleExpandLevel: 'all'
        }} />
        <Helmet>
            <meta charSet="utf-8" />
            <title>IVA API - Illuma Shield</title>
            <link rel="canonical" href="https://docs.illuma-labs.com/api/iva" />
            <meta name="description" content="The Illuma Shield IVA API can be used by external applications to manage the verification process."/>
        </Helmet>
    </React.Fragment>
  );
};

export default Iva;

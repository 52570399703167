import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {Helmet} from "react-helmet";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  h1: {
    fontSize: '5rem',
    marginTop: '10vw',
    marginBottom: 0
  },
  h2: {
    fontSize: '1.5rem',
  },
  button: {
    margin: theme.spacing(1),
  },
  content: {
    textAlign: 'center'
  }
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="top"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={8} className={classes.content}>
          <h1 className={classes.h1}>Oops!</h1>
          <h2 className={classes.h2}>Sorry, the page you were looking for doesn't exist or might have been moved.</h2>
          <Button className={classes.button} variant="contained" href="https://www.illuma-labs.com">Go Home</Button>
        </Grid>
      </Grid> 
      <Helmet>
          <meta charSet="utf-8" />
          <title>Not Found - Illuma Shield</title>
          <link rel="canonical" href="https://docs.illuma-labs.com/404.html" />
          <meta name="description" content="Sorry, that page doesn't exist."/>
      </Helmet>
    </React.Fragment>
  );
};

export default NotFound;

import React from 'react';
import {Helmet} from "react-helmet";
import { RedocStandalone } from 'redoc';

const Files = () => {
  return (
    <React.Fragment>
        <RedocStandalone specUrl="/illuma-shield-file-api-v1.0.yaml" options={{
            theme: {
            typography: {
                fontSize: '1rem'
            },
            colors: {
                primary: {
                main: '#263238'
                }
            }
            },
            jsonSampleExpandLevel: 'all'
        }} />
        <Helmet>
            <meta charSet="utf-8" />
            <title>File API - Illuma Shield</title>
            <link rel="canonical" href="https://docs.illuma-labs.com/api/files" />
            <meta name="description" content="The Illuma Shield File API can be used to verify a person by using a recording of a persons voice."/>
        </Helmet>
    </React.Fragment>
  );
};

export default Files;
